import { Spin, Typography } from 'antd';

import { FullPageSpinnerProps } from './props';

export const FullPageSpinner: React.FC<FullPageSpinnerProps> = ({
  subtitle,
  title,
}) => (
  <div className="w-screen h-screen flex justify-center items-center space-y-6 flex-col">
    <Spin size="large" />
    <div className="text-center">
      {title && (
        <Typography.Title className="text-primary-3" level={4}>
          {title}
        </Typography.Title>
      )}
      {subtitle && (
        <Typography.Paragraph className="text-primary-4">
          {subtitle}
        </Typography.Paragraph>
      )}
    </div>
  </div>
);
