export const header = {
  employees: 'Қызметкерлер',
  events: 'Іс-шаралар',
  help: 'Көмек',
  liveFeed: 'Өзекті таспа',
  main: 'Бас',
  news: 'Жаңалықтар',
  notifications: 'Хабарламалар',
  signIn: 'Кіру',
  signOut: 'Шығу',
  test: 'Тестілеу',
};
