const months =
  'january_february_march_april_may_june_july_august_september_october_november_december'.split(
    '_'
  );

const longDateFormat = {
  L: 'DD/MM/YYYY',
  LL: 'D MMMM YYYY',
  LLL: 'D MMMM YYYY HH:mm',
  LLLL: 'dddd D MMMM YYYY HH:mm',
  LT: 'HH:mm',
  LTS: 'HH:mm:ss',
};

const dayOfMonthOrdinalParse = new RegExp(/\d{1,2}(er|e)/);

export const dateLocales = {
  dayOfMonthOrdinalParse,
  longDateFormat,
  months,
};
