export const covid19 = {
  covidSummary: {
    inKazAtomProm: 'В разрезе АО НАК Казатомпром',
    inKazakhstan: 'В Казахстане',
    title: 'Сводная информация по Covid-19',
  },
  healthInsuranceFund: 'Фонд медицинского страхования',
  hotLine:
    'Круглосуточно открыта горячая линия по вопросам нового коронавируса',
  nationalCenterForPublicHealth:
    'Национальный центр общественного здравоохранения Министерства здравоохранения РК',
};
