import { formBuilder } from '@/i18n/ru/formio/formBuilder';

import { header } from './app/header';
import { sideBar } from './app/sideBar';
import { newsModule } from './news';

export const ruCommon = {
  formBuilder,
  header,
  newsLanding: newsModule,
  sideBar,
};
