export const liveFeed = {
  additionalProps: 'Қосымша опциялар',
  allEvents: 'Барлық оқиғалар',
  block: 'Блок',
  comment: 'Пікір қалдыру',
  department: 'Департамент',
  like: 'Ұнайды',
  position: 'Лауазым',
  section: 'Бөлім',
  share: 'Бөлісу',
};
