export const news = {
  addedBy: 'Қосты: ',
  allEpisodes: 'Барлық шығарылымдар',
  allNews: 'Барлық жаңалықтар',
  clear: 'Тазарту',
  copyLink: 'Сілтемені көшіру',
  createPost: 'Жарияланым жасау',
  daysAgo: 'Күн бұрын',
  kazatomPromNews: 'Қазатомөнеркәсіп жаңалықтары',
  latestNews: 'Жүйенің соңғы жаңалықтары',
  liveFeed: 'Өзекті таспа',
  newsTitle: 'Жаңалықтар',
  position: 'Лауазым',
  published: 'Жарияланды',
};
