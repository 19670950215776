const months =
  'қаңтар_ақпан_наурыз_сәуір_мамыр_маусым_шілде_тамыз_қыркүйек_қазан_қараша_желтоқсан'.split(
    '_'
  );
const monthsShort =
  'қаң_ақпан_нау._сәуір_мамыр_маус._шіл._тамыз_қыр._қазан_қар._жел.'.split('_');

const weekdays =
  'дүйсенбi_сейсенбi_сәрсенбі_бейсенбі_жұма_сенбі_жексенбi'.split('_');

const weekdaysShort = 'дүй._сей._сәр._бей._жұм._сен._жек.'.split('_');

const weekdaysMin = 'Дү_Се_Сә_Бе_Жұ_Се_Же'.split('_');

const longDateFormat = {
  L: 'DD/MM/YYYY',
  LL: 'D MMMM YYYY',
  LLL: 'D MMMM YYYY HH:mm',
  LLLL: 'dddd D MMMM YYYY HH:mm',
  LT: 'HH:mm',
  LTS: 'HH:mm:ss',
};

const relativeTime = {
  M: 'бір ай',
  MM: '%d ай',
  d: 'бір күн',
  dd: '%d күн',
  future: '%s-де',
  h: 'бір сағат',
  hh: '%d сағат',
  m: 'бір минут',
  mm: '%d минут',
  past: '%s бұрын',
  s: 'quelques secondes',
  y: 'ж',
  yy: 'жж',
};

const dayOfMonthOrdinalParse = new RegExp(/\d{1,2}(er|e)/);

export const dateLocales = {
  dayOfMonthOrdinalParse,
  longDateFormat,
  months,
  monthsShort,
  relativeTime,
  weekdays,
  weekdaysMin,
  weekdaysShort,
};
