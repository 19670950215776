import React from 'react';
import { render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import ru_RU from 'antd/es/locale/ru_RU';
import { Locale } from 'antd/es/locale-provider';
import en_US from 'antd/lib/locale/en_US';
import kk_KZ from 'antd/lib/locale/kk_KZ';
import { registerLanguageDictionary, ruRU } from 'handsontable/i18n';
import { registerAllModules } from 'handsontable/registry';
import moment, { locale } from 'moment';

import { FormBuilderStyle } from '@components/templates/FormBuilderStyle';
import { GlobalStyle } from '@components/templates/GlobalStyle';
import { AppQueryContextProvider } from '@context/AppQueryContext';
import { BlockerPromptContextProvider } from '@context/BlockerPromptContext';
import { PageMetaContextProvider } from '@context/PageMetaContext';
import { UserContextProvider } from '@context/UserContext';
import { ignoreConsoleErrors } from '@utils/consoleError';

import AppRoutes from './AppRoutes';
import { dateLocales as enLocales } from './i18n/en/moment/enLocale';
import i18n from './i18n/i18n';
import { dateLocales as kkLocales } from './i18n/kz/moment/kkLocale';
import reportWebVitals from './reportWebVitals';

import './index.less';
import 'handsontable/dist/handsontable.full.css';
import 'react-js-cron/dist/styles.css';
import 'moment/locale/ru';
import '@fullcalendar/react/dist/vdom';

registerAllModules();
registerLanguageDictionary(ruRU);
moment().locale(i18n.language);
ignoreConsoleErrors();
const currentLocale = () => {
  switch (i18n.language) {
    case 'kk':
      locale('kk', kkLocales);
      return kk_KZ;
    case 'en':
      locale('en', enLocales);
      return en_US;
    default:
      return ru_RU;
  }
};

const localLocale: Locale = currentLocale();

render(
  <React.StrictMode>
    <GlobalStyle />
    <FormBuilderStyle />
    <ConfigProvider locale={localLocale}>
      <HelmetProvider>
        <PageMetaContextProvider>
          <UserContextProvider>
            <BlockerPromptContextProvider>
              <AppQueryContextProvider>
                <BrowserRouter>
                  <AppRoutes />
                </BrowserRouter>
              </AppQueryContextProvider>
            </BlockerPromptContextProvider>
          </UserContextProvider>
        </PageMetaContextProvider>
      </HelmetProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
