export const sideBar = {
  allModules: 'Барлық модульдер',
  calendar: 'Күнтізбе',
  corporate: 'Корпоративтік',
  education: 'Оқыту',
  ekapDrive: 'еКАП дискі',
  hrRecruiting: 'HR-Рекрутинг',
  pass: 'Өткізу',
  personal: 'Жеке',
  services: 'Cервистер',
  taskPanel: {
    allTasks: 'Барлық тапсырмалар',
    approval: 'Келісу',
    assigned: 'Маған тағайындалған',
    assignedReports: 'Тағайындалған есептер',
    created: 'Құрылған',
    sighting: 'Есептерге виза қою',
    signing: 'Қол қою',
    taskPanelTitle: 'Тапсырмалар тақтасы',
  },
  medicalCheckup: {
    title: 'Медициналық байқау',
    preshift: 'Алдын ала ауысым',
    outshift: 'Ауысымнан кейінгі',
    log: 'Қабылдау/қабылдамау журналы',
  },
  workPermit: {
    title: 'Наряд допуск',
    list: 'Наряд допуск',
    instructionLog: 'Журнал инструктажей',
    admission: 'Ежедневный допуск',
    workLog: 'Журнал учет работ НД',
    instruction: 'Инструктажи',
  },
  taskPermit: {
    title: 'Наряд задания',
    list: 'Наряд задания',
    instructionLog: 'Журнал инструктажей',
    admission: 'Ежедневный допуск',
    taskLog: 'Журнал учет работ НЗ',
    instruction: 'Инструктажи',
  },
  emlployees: 'Қызметкерлер'
};
