import type { FC } from 'react';

import tw from 'twin.macro';

import { css, Global } from '@emotion/react';

export const FormBuilderStyle: FC = () => (
  <Global
    styles={css`
      .alert-danger {
        display: none;
      }
      .formcomponents {
        ${tw`!max-w-[323px] !w-[323px] !min-w-[323px] border-l border-gray-4`}
      }

      .formcomponent {
        ${tw`bg-gray-2 p-2 !mt-2 text-gray-9 text-xs hover:bg-blue-5 hover:text-primary visible`}
      }

      .card {
        ${tw`border-none`}
      }

      .card-header {
        ${tw`bg-transparent border-none pt-2`}
        h5 {
          ${tw`text-gray-8`}
          .btn {
            ${tw`text-base focus:shadow-none	`}
          }
        }
      }

      .formio-component {
        .col-form-label {
          ${tw`text-gray-7 text-sm`}
        }
      }
      .formbuilder {
        ${tw`font-sans flex-row-reverse flex-nowrap ml-0`}
        height: calc(100vh - 273px);
        .formarea {
          ${tw`overflow-auto bg-gray-0 p-0 flex-auto pt-[64px] border-l border-b border-gray-4 max-w-[100%]`}
          .drag-and-drop-alert {
            ${tw`bg-transparent text-gray-8 border-none`}
          }
          .formio-form {
            ${tw`w-[90%] m-auto max-w-[815px]`}
            .col-form-label {
              ${tw`text-gray-7 text-sm`}
            }
          }
          .formio-component-columns {
            ${tw`w-full m-0`}
          }
        }
        .builder-sidebar {
          ${tw`w-full mt-[110px] border-t border-gray-4`}
          .builder-sidebar_search {
            display: none;
          }
        }
      }
      .formio-dialog {
        a[href$='help.form.io'] {
          display: none !important;
        }
      }
      .formioPrimaryButton {
        button {
          ${tw`text-sm h-10 bg-darkBlue-15 text-white hover:bg-blue-85 hover:text-white active:!bg-blue-40 active:!text-white`}
        }
      }
      .formioAddSigningStageButton {
        button {
          ${tw`text-sm h-10 bg-transparent text-darkBlue-15 hover:bg-blue-15 hover:text-darkBlue-15 active:!bg-blue-35 active:!text-darkBlue-15`}
        }
      }
      .formioRemoveSigningStageButton {
        ${tw`mb-0 mt-1`}
        button {
          ${tw`text-sm bg-gray-7 text-white hover:bg-gray-8 active:!bg-gray-8`}
        }
      }
      .signingStagesPanel {
        ${tw`mt-6`}
        & > .field-required {
          ${tw`hidden`}
        }
        & > button {
          ${tw`text-sm h-10 bg-transparent text-darkBlue-15 hover:bg-blue-15 hover:text-darkBlue-15 active:!bg-blue-35 active:!text-darkBlue-15`}
          i {
            ${tw`mr-[13px]`}
          }
        }
        .list-group-item {
          ${tw`py-5 pr-5 pl-8`}
        }
        .editRow {
          ${tw`py-0`}
        }
        .removeRow {
          ${tw`py-0`}
        }
        .editgrid-actions {
          ${tw`mt-6`}
          .btn-primary {
            ${tw`bg-darkBlue-15 text-sm h-8 min-w-[32px] text-sm hover:bg-darkBlue-75 focus:!bg-darkBlue-45 active:!bg-darkBlue-45 font-normal`}
          }
          .btn-danger {
            ${tw`bg-transparent hover:bg-blue-35 h-8 min-w-[32px] text-sm text-sm text-darkBlue-15 border border-darkBlue-15 font-normal`}
            :active {
              ${tw`!bg-blue-40 !text-darkBlue-15`}
            }
            :focus {
              ${tw`!bg-blue-40 !text-darkBlue-15`}
            }
          }
        }
      }
      .signersPanelHeader {
        ${tw`text-gray-8 text-base mb-10`}
      }
      .signersListGrid {
        table {
          ${tw`bg-gray-0 !border !border-gray-3`}
          thead {
            ${tw`hidden`}
          }
          button {
            ${tw`hover:bg-transparent active:bg-transparent focus:bg-transparent mt-[6px] float-right`}
            i {
              ${tw`text-gray-7`}
            }
          }
        }
        .datagrid-table td {
          ${tw`!border-none !h-12 !max-h-12 p-0`}
        }
        .datagrid-table tr {
          ${tw`border border-gray-3`}
        }
        .datagrid-table tr > td:last-child {
          ${tw`w-8 pr-1`}
        }
        .table-bordered {
          ${tw`bg-gray-0 !border !border-gray-3`}
        }
      }
      .signerItemPanel {
        ${tw`text-gray-8 flex items-center w-full justify-between pl-4 pr-3 h-12`}
        & > div {
          ${tw`w-full flex items-center justify-between`}
        }
        .count {
          ${tw`text-gray-6 w-auto`}
        }
        .formio-errors {
          ${tw`!hidden`}
        }
        .position {
          ${tw`!text-gray-6`}
        }
        .organization {
          ${tw`!text-gray-6 truncate`}
        }
      }
      .formioSelect {
        ${tw`mt-[26px]`}
        .form-control {
          ${tw`border-gray-5 rounded-none`}
        }
        .col-form-label {
          ${tw`text-gray-7 text-xs font-medium`}
        }
        .choices__list--multiple {
          .choices__item {
            ${tw`bg-gray-3 border-0 h-[20.5px] inline-flex items-center`}
            span {
              ${tw`text-gray-8`}
            }
            button {
              ${tw`text-gray-7 border-0 ml-[7.52px]`}
            }
            & > div > div:last-child {
              ${tw`hidden`}
            }
          }
          .is-highlighted {
            ${tw`bg-gray-4`}
          }
        }
        .position {
          ${tw`!text-gray-6`}
        }
        .name {
          ${tw`!text-gray-8`}
        }
        .organization {
          ${tw`!text-gray-6 truncate`}
        }
      }
      .formioRadio {
        ${tw`mt-9`}
        .col-form-label {
          ${tw`text-gray-8 text-base mb-[2px] py-0`}
          .fa {
            ${tw`!text-darkBlue-15 ml-1`}
          }
        }
        .form-check {
          ${tw`pt-3`}
          .form-check-label {
            ${tw`flex text-gray-8 text-sm`}
            input {
              ${tw`bottom-[1px]`}
            }
          }
        }
      }
      .formioCheckbox {
        ${tw`mt-9`}
        .form-check-label {
          ${tw`flex text-gray-8 text-sm`}
          input {
            ${tw`bottom-[1px]`}
          }
          .fa {
            ${tw`!text-darkBlue-15 ml-1`}
          }
        }
      }
      .formioSettingsTab {
        &.card-body {
          ${tw`pt-0 bg-gray-0`}
        }
      }

      .formio-disabled-field {
        .dropdown {
          ${tw`cursor-not-allowed`}
          background: #e9ecef;
        }
        .choices__input {
          ${tw`cursor-not-allowed`}
          background: #e9ecef !important;
        }
      }

      .ant-select-selector {
        .formio-user-position {
          display: none;
        }
        .formio-user-orgUnit-label {
          display: none;
        }
      }

      .formio-down-button {
        ${tw`mb-0 flex`}
        position: absolute;
        top: 15.5px;
        left: 1px;
        button {
          ${tw`p-0 bg-transparent text-gray-7 hover:text-gray-7 hover:!bg-gray-3 active:!text-gray-7 active:!bg-gray-3`}
          span {
            ${tw`ml-1 mt-[4.5px]`}
          }
        }
      }
      .formio-up-button {
        ${tw`mb-0 flex`}
        position: absolute;
        top: 2.5px;
        left: 1px;
        button {
          ${tw`p-0 bg-transparent text-gray-7 hover:text-gray-7 hover:!bg-gray-3 active:!text-gray-7 active:!bg-gray-3`}
          span {
            ${tw`ml-1 mt-[4px]`}
          }
        }
      }

      .formio-alert-message {
        ${tw`text-darkBlue-80 mb-8 !bg-darkBlue-80 bg-opacity-10 mt-5`}
        border: 1px solid #8a9cb8;
        padding: 15px 15px 15px 24px;
      }

      .formioBinDataMap {
        .formioSelect {
          ${tw`mt-0`}
          .choices__item {
            ${tw`!h-auto`}
            white-space: break-spaces;
            word-break: break-word;
          }
          .choices__list--multiple {
            .choices__item {
              ${tw`max-w-[250px]`}
            }
          }
        }
        .formio-component-textfield {
          ${tw`min-w-[150px]`}
          input {
            ${tw`rounded-none border-gray-5 text-gray-8`}
          }
        }
        .formio-button-remove-row {
          ${tw`hover:!bg-transparent active:!bg-transparent focus:!bg-transparent`}
          i {
            ${tw`text-gray-7`}
          }
        }
        .formio-button-add-row {
          ${tw`w-full h-[57px] text-darkBlue-15 bg-blue-7 hover:!bg-blue-15 active:!bg-blue-15 focus:!bg-blue-15 text-darkBlue-15`}
        }
        table > tfoot > tr > td {
          ${tw`p-0`}
        }
        table {
          ${tw`border-0`}
          thead > tr > th {
            ${tw`border-0 bg-blue-7 text-darkBlue-15 !border-b border-blue-25`}
          }
          thead > tr > th:first-child {
            ${tw`!border-r border-blue-25`}
          }
          tbody > tr > td {
            ${tw`border-0 !border-b border-gray-3 align-middle`}
          }
          tbody > tr > td:first-child {
            ${tw`!border-r border-gray-3`}
          }
          tfoot > tr > td {
            ${tw`border-0`}
          }
        }
      }
      .component-edit-container
        > div:last-child
        > div:last-child
        > div:last-child
        > .btn-danger {
        ${tw`hidden`}
      }
    `}
  />
);
