export const header = {
  employees: 'Сотрудники',
  events: 'Мероприятия',
  help: 'Помощь',
  liveFeed: 'Живая лента',
  main: 'Главная',
  news: 'Новости',
  notifications: 'Уведомления',
  signIn: 'Войти',
  signOut: 'Выйти',
  test: 'Тестирование',
};
