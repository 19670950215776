export const sideBar = {
  addModule: 'Add a module',
  allModules: 'All modules',
  calendar: 'Calendar',
  corporate: 'Corporate',
  education: 'Training',
  ekapDrive: 'eKAP drive',
  hrRecruiting: 'HR-recruiting',
  pass: 'Pass',
  personal: 'Personal',
  services: 'Services',
  taskPanel: {
    allTasks: 'All tasks',
    approval: 'Coordination ',
    assigned: 'Assigned to me',
    assignedReports: 'Assigned Reports',
    created: 'Created',
    sighting: 'Sighting of reports',
    signing: 'Signing',
    taskPanelTitle: 'Task bar',
  },
  medicalCheckup: {
    title: 'Medical checkup',
    preshift: 'Preshift',
    outshift: 'Outshift',
    log: 'Admission/non-admission log',
  },
  workPermit: {
    title: 'work permit',
    list: 'Наряд допуск',
    instructionLog: 'Журнал инструктажей',
    admission: 'Daily admission',
    workLog: 'Журнал учет работ НД',
    instruction: 'Инструктажи',
  },
  taskPermit: {
    title: 'task permit',
    list: 'Task permit',
    instructionLog: 'Журнал инструктажей',
    admission: 'Daily admission',
    taskLog: 'Журнал учет работ НЗ',
    instruction: 'Инструктажи',
  },
  employess: 'Employees'
};
