import { initReactI18next } from 'react-i18next';

import i18next, { use } from 'i18next';

import { enTranslation } from './en/translation';
import { kzTranslation } from './kz/translation';
import { ruTranslation } from './ru/translation';

const resources = {
  en: enTranslation,
  kk: kzTranslation,
  ru: ruTranslation,
};

const defaultLang = 'ru';

const persistedLng = localStorage.getItem('EKAP_APP_LNG');

if (!persistedLng) {
  localStorage.setItem('EKAP_APP_LNG', defaultLang);
}

use(initReactI18next).init({
  fallbackLng: defaultLang,
  lng: persistedLng ?? defaultLang,
  load: 'currentOnly',
  resources,
});

i18next.on('languageChanged', (lng) => {
  localStorage.setItem('EKAP_APP_LNG', lng);
});

export default i18next;
