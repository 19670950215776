export const hse = {
  dangerousActions: 'Опасных действий',
  dangerousConditions: 'Опасных условий',
  daysCount: 'Количество дней',
  hseCounters: 'Счетчики HSE',
  inPastWeek: 'За истекшую неделю',
  rootOrg: 'АО «НАК «Казатомпром»',
  sinceYearBeginning: 'С начала текущего года',
  stateOfDate: 'По состоянию на 30 сентября 2021 г.',
  withoutAccidents: 'без несчастных случаев',
  withoutCarCrashes: 'без ДТП',
  withoutCrashes: 'без аварий',
};
