export const hse = {
  dangerousActions: 'Қауіпті әрекеттер',
  dangerousConditions: 'Қауіпті жағдайлар',
  daysCount: 'күндер саны',
  hseCounters: 'HSE есептегіштері',
  inPastWeek: 'Өткен аптада',
  rootOrg: '«Қазатомөнеркәсіп» ҰАК» АҚ',
  sinceYearBeginning: 'Ағымдағы жылдың басынан бастап',
  stateOfDate: '2021 жылғы 30 қыркүйектегі жағдай бойынша',
  withoutAccidents: 'Жазатайым оқиғалар болмаған',
  withoutCarCrashes: 'Жол-көлік оқиғасы болмаған',
  withoutCrashes: 'Авариялар болмаған',
};
