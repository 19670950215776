export const sideBar = {
  allModules: 'Все модули',
  calendar: 'Календарь',
  copyright: 'Copyright © ТОО “KAP Technology“',
  corporate: 'Корпоративный',
  education: 'Обучение',
  ekapDrive: 'Диск еКАП',
  hrRecruiting: 'HR-Рекрутинг',
  library: 'KAP Library',
  newUser: 'Новый пользователь',
  pass: 'Пропуск',
  personal: 'Личный',
  rights: '2022-2023 Все права защищены',
  services: 'Сервисы',
  taskPanel: {
    allTasks: 'Все задачи',
    approval: 'Согласование',
    assigned: 'Назначенные мне',
    assignedReports: 'Назначенные отчеты',
    created: 'Созданные',
    sighting: 'Визирование отчетов',
    signing: 'Подписание',
    taskPanelTitle: 'Панель задач',
  },
  medicalCheckup: {
    title: 'Медицинский осмотр',
    preshift: 'Предсменный',
    outshift: 'Послесменный',
    log: 'Журнал допуск/не допуск',
    npa: 'НПА и инструктажи',
  },
  workPermit: {
    title: 'Наряд допуск',
    list: 'Наряд допуск',
    instructionLog: 'Инструктажи НД',
    admission: 'Ежедневный допуск',
    workLog: 'Журнал учет работ НД',
    instruction: 'Инструктажи',
  },
  pna: {
    title: 'ПНА модуль',
    pna: 'Погружные насосные агрегаты',
    elecetricMotor: 'Электродвигатели',
    pumpPart: 'Насосная часть',
    sparePart: 'Запчасти',
  },
  taskPermit: {
    title: 'Наряд задания',
    list: 'Наряд задания',
    instructionLog: 'Инструктажи НЗ',
    admission: 'Ежедневный допуск',
    taskLog: 'Журнал учет работ НЗ',
    instruction: 'Инструктажи',
  },
  employees: 'Сотрудники'
};
