import { FC, useContext, useState } from 'react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';

import { notification } from 'antd';
import { AxiosError } from 'axios';

import { authApi } from '@api/authApi';
import { appLocalStorage } from '@utils/appLocalStorage';

import { UserContext } from './UserContext';

export const AppQueryContextProvider: FC = (props) => {
  const { logout, setAuthToken, setIsSettled } = useContext(UserContext);

  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          cacheTime: 0,
          refetchOnWindowFocus: false,
          retry: false,
        },
      },
      mutationCache: new MutationCache({
        onError: async (error, variables, context, mutation) => {
          const err = error as AxiosError;

          const isAuthRequest =
            err.response?.config.url?.includes(
              '/protocol/openid-connect/token'
            ) &&
            (err.response?.config.method === 'POST' ||
              err.response?.config.method === 'post');

          const receivedUnauthorizedError = err.response?.status !== 401;

          if (receivedUnauthorizedError || isAuthRequest) return;

          await mutation.cancel();
          const refreshToken = appLocalStorage.getRefreshToken() as string;
          authApi
            .refreshAuthToken(refreshToken)
            .then(async (data) => {
              const { access_token, refresh_token } = data.data;
              appLocalStorage.persistTokens({
                refreshToken: refresh_token,
                token: access_token,
              });
              setAuthToken(access_token);
            })
            .catch((_err) => {
              notification.warn({
                key: 'refresh_token_error',
                message: 'Срок действия сессии истек',
              });
              logout();
            });
        },
      }),
      queryCache: new QueryCache({
        onError: async (error, query) => {
          const err = error as AxiosError;
          if (!err.response) {
            setIsSettled(true);
            return;
          }
          if (err.response?.status !== 401) return;
          await query.invalidate();
          const refreshToken = appLocalStorage.getRefreshToken() as string;
          authApi
            .refreshAuthToken(refreshToken)
            .then(async (data) => {
              const { access_token, refresh_token } = data.data;
              appLocalStorage.persistTokens({
                refreshToken: refresh_token,
                token: access_token,
              });
              setAuthToken(access_token);
              await query.fetch();
            })
            .catch((_err) => {
              notification.warn({
                key: 'refresh_token_error',
                message: 'Срок действия сессии истек',
              });
              logout();
            });
        },
      }),
    })
  );

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
    </QueryClientProvider>
  );
};
