import * as qs from 'qs';

import { api } from '@api/index';

import { AppUserInfo } from '@/types/AppUser';

const getUserByUsername = (username: string, password: string) => {
  return api
    .post<{ access_token: string; refresh_token: string }>(
      process.env.REACT_APP_KEYCLOAK_URL +
        '/realms/ekap-platform/protocol/openid-connect/token',
      qs.stringify({
        client_id: 'ekap-frontend-app',
        grant_type: 'password',
        password,
        username,
      })
    )
    .then((res) => res.data);
};

const getUserByPhoneNumber = (username?: string, requestId?: string) => {
  return api
    .post<{ access_token: string; refresh_token: string }>(
      process.env.REACT_APP_KEYCLOAK_URL +
        '/realms/ekap-platform/protocol/openid-connect/token',
      qs.stringify({
        client_id: 'ekap-frontend-app',
        grant_type: 'password',
        requestId: requestId,
        username,
      })
    )
    .then((res) => res.data);
};

const getUserByIin = (
  iin?: string,
  username?: string,
  code?: string,
  requestId?: string
) => {
  return api
    .post<{ access_token: string; refresh_token: string }>(
      process.env.REACT_APP_KEYCLOAK_URL +
        '/ekap-platform/protocol/openid-connect/token',
      qs.stringify({
        client_id: 'ekap-frontend-app',
        code,
        grant_type: 'password',
        iin: iin,
        requestId: requestId,
        username,
      })
    )
    .then((res) => res.data);
};

const getUserInfo = () =>
  api.get<AppUserInfo>(`/users/current`).then((res) => res.data);

const checkPhoneNumber = (phoneNumber?: string, iin?: string) => {
  const onlyNumbers = phoneNumber?.replace(/\D/g, '');
  const formattedPhoneNumber = onlyNumbers?.replace(onlyNumbers[0], '8');
  return api.post(
    process.env.REACT_APP_KEYCLOAK_URL +
      '/realms/ekap-platform/ekap-validation-requests/generate',
    {
      clientId: 'ekap-frontend-app',
      iin,
      phoneNumber: formattedPhoneNumber,
    }
  );
};

const refreshAuthToken = (refreshToken?: string) => {
  return api.post<{ access_token: string; refresh_token: string }>(
    process.env.REACT_APP_KEYCLOAK_URL +
      '/realms/ekap-platform/protocol/openid-connect/token',
    qs.stringify({
      client_id: 'ekap-frontend-app',
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    })
  );
};

const validatePhoneNumber = (
  phoneNumber?: string,
  otp?: string,
  requestId?: string
) => {
  const onlyNumbers = phoneNumber?.replace(/\D/g, '');
  const editedPhone = onlyNumbers?.replace(onlyNumbers[0], '8');
  return api.post(
    process.env.REACT_APP_KEYCLOAK_URL +
      `/realms/ekap-platform/ekap-validation-requests/${requestId}/validate`,
    {
      clientId: 'ekap-frontend-app',
      otp,
      phoneNumber: editedPhone,
    }
  );
};

const validateIin = (requestCode: string, requestState: string) => {
  return api.post(
    process.env.REACT_APP_KEYCLOAK_URL +
      `/realms/ekap-platform/ekap-validation-requests/${requestState}/validate/iin`,
    {
      clientId: 'ekap-frontend-app',
      code: requestCode,
    }
  );
};

export const authApi = {
  checkPhoneNumber,
  getUserByIin,
  getUserByPhoneNumber,
  getUserByUsername,
  getUserInfo,
  refreshAuthToken,
  validateIin,
  validatePhoneNumber,
};
