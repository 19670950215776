import React, { createContext, FC, useCallback, useRef, useState } from 'react';

import { ConfirmationModal } from '@components/organisms/ConfirmationModal';

export const BlockerPromptContext = createContext<
  (message?: React.ReactNode) => Promise<void>
>(() => Promise.resolve());

const DEFAULT_MSG = `Вы уверены? Введенные данные могут быть потеряны.`;

export const BlockerPromptContextProvider: FC = (props) => {
  const cbReject = useRef<() => void>();
  const cbResolve = useRef<() => void>();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<React.ReactNode>();

  const showPrompt = useCallback(
    (_message?: React.ReactNode) =>
      new Promise<void>((resolve, reject) => {
        cbReject.current = reject;
        cbResolve.current = resolve;
        setMessage(_message);
        setOpen(true);
      }),
    []
  );

  const close = useCallback(() => {
    if (cbReject.current) {
      setMessage(undefined);
      cbReject.current();
    }
    setOpen(false);
  }, []);

  const confirm = useCallback(() => {
    if (cbResolve.current) {
      setMessage(undefined);
      cbResolve.current();
    }
    setOpen(false);
  }, []);

  return (
    <BlockerPromptContext.Provider value={showPrompt}>
      {props.children}

      <ConfirmationModal
        cancelText="Отменить"
        okText="Продолжить"
        visible={open}
        onCancel={() => {
          close();
        }}
        onOk={() => {
          confirm();
        }}
      >
        {message ?? DEFAULT_MSG}
      </ConfirmationModal>
    </BlockerPromptContext.Provider>
  );
};
