export const hse = {
  dangerousActions: 'Dangerous actions',
  dangerousConditions: 'Dangerous conditions',
  daysCount: 'number of days',
  hseCounters: 'HSE meters',
  inPastWeek: 'During the past week',
  rootOrg: 'JSC NAC Kazatomprom',
  sinceYearBeginning: 'Since the beginning of this year',
  stateOfDate: 'As of September 30, 2021',
  withoutAccidents: 'Without accidents',
  withoutCarCrashes: 'Without car accidents',
  withoutCrashes: 'Without crashes',
};
