import type { FC } from 'react';

import tw from 'twin.macro';

import { css, Global } from '@emotion/react';

export const GlobalStyle: FC = () => (
  <Global
    styles={css`
      button[type='submit'] {
        background-color: #284892 !important;
      }
      .btn {
        border-radius: 0 !important;
        border: none;
      }
      :root {
        --fc-button-bg-color: #284892;
        --fc-button-active-bg-color: #3459ad;
        --fc-button-hover-bg-color: #3f6ac9;
      }
      ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
      }

      ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        -webkit-border-radius: 10px;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #a3a2a3;
      }
      .dark {
        .docs-story {
          ${tw`bg-primary`}
        }
        .ant-btn-default {
          ${tw`border-white text-white`}
        }
      }
      .ant-menu-submenu-popup {
        display: none !important;
      }

      .custom-ant-message-success {
        .ant-message-notice-content {
          background: #e8f7f1;
          color: #168f63;
        }
      }
      .custom-ant-message-error {
        .ant-message-notice-content {
          color: red;
        }
      }
      .ant-file-upload-item {
        &:hover {
          ${tw`hover:bg-blue-15`}
          article {
            ${tw`text-darkBlue-15`}
          }
        }
      }
      .ant-message {
        position: fixed;
        bottom: 30px !important;
        top: unset !important;
        .ant-message-notice-content {
          .ant-message-custom-content {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .user-label {
        label {
          ${tw`text-xs h-full mb-1 text-gray-7 flex flex-row-reverse justify-end before:ml-1`}
        }
        label::after {
          content: '';
          margin: 0px;
        }
      }
      .user-tooltip {
        .ant-tooltip-content {
          ${tw`-ml-4`}
          .ant-tooltip-inner {
            ${tw`border border-darkBlue-30 ring-2 ring-blue-15 px-4 py-3`}
          }
          .ant-tooltip-arrow {
            ${tw`-left-1`}
            span {
              background-color: blue;
            }
            .ant-tooltip-arrow-content {
            }
          }
        }
      }
      .report-popover {
        .ant-popover-inner-content {
          ${tw`h-[100vh] w-full overflow-y-scroll`}
        }
      }
      .user-popover {
        padding-top: 8px;
        .ant-popover-arrow {
          display: none;
        }
        .ant-popover-inner {
          ${tw`border border-darkBlue-30 ring-2 ring-blue-15 rounded-none`}
        }
        .ant-popover-inner-content {
          ${tw`px-0 py-1`}
        }
      }
      .kanban-filter-popover {
        ${tw`w-[240px]`}
      }
      .custom-popover {
        ${tw`pt-1`}
        .ant-popover-inner-content {
          ${tw`bg-primary-6 text-sm text-white py-2 px-3`}
        }
        .ant-popover-arrow {
          display: none;
        }
      }
      .kanban-popover {
        .ant-popover-inner {
          ${tw`border-l border-b border-r border-darkBlue-60 w-[257px] pt-[-16px] shadow-popover`}
        }
        .ant-popover-inner-content {
          ${tw`px-4 pb-3.5 pt-[13px]`}
        }
        .ant-popover-title {
          ${tw`p-0 border-none`}
        }
        .ant-popover-arrow {
          display: none;
        }
        &.ant-popover-placement-bottom {
          ${tw`!pt-1`}
        }
      }
      .info-popover {
        .ant-popover-inner {
          ${tw`w-[257px] pt-[-16px]`}
        }
        .ant-popover-inner-content {
          ${tw`px-4 pb-3.5 pt-[13px]`}
        }
      }
      .users-popover {
        .ant-popover-inner {
          ${tw`border-l border-r border-b border-darkBlue-60 w-[368px] py-0 pb-0 shadow-popover rounded-none`}
        }
        .ant-popover-inner-content {
          ${tw`px-4 pb-4 pt-0`}
        }
        .ant-popover-title {
          ${tw`p-0 border-none min-h-[18px]`}
        }
        .ant-popover-arrow {
          display: none;
        }
        &.ant-popover-placement-bottomLeft {
          ${tw`!pt-2`}
        }
      }
      .edition-popover {
        .ant-popover-inner {
          ${tw`border-t border-r border-b border-darkBlue-60 w-[180px] p-0 shadow-popover rounded-none`}
        }
        .ant-popover-inner-content {
          ${tw`p-0`}
        }
        .ant-popover-title {
          display: none;
        }
        .ant-popover-arrow {
          display: none;
        }
        &.ant-popover-placement-rightTop {
          ${tw`!pl-2`}
        }
      }
      .assignee-popover {
        .ant-popover-inner {
          ${tw`!w-[250px]`}
        }
        .ant-popover-title {
          ${tw`!min-h-0`}
        }
      }

      .confirm-popover {
        &.ant-popover-placement-bottomRight {
          ${tw`pt-1`}
        }
        .ant-popover-inner {
          ${tw`!w-[276px]`}
        }
        .ant-popover-title {
          ${tw`!min-h-0`}
        }
      }

      .select-dropdown {
        &.ant-select-dropdown {
          ${tw`rounded-none !border !border-darkBlue-30 mt-1.5`}
          box-shadow: 0 0 0 2px #C8D7F6 !important;
        }
        .ant-select-item {
          ${tw`items-center text-gray-8 text-sm hover:text-darkBlue-15 hover:bg-blue-5 active:text-darkBlue-15 active:bg-blue-15`}
        }
        .rc-virtual-list-scrollbar {
          display: block !important;
        }
      }

      .select-dropdown-break-spaces {
        .ant-select-item-option-content {
          white-space: break-spaces;
          word-break: break-word;
        }
      }

      .checkbox-select {
        .ant-select-item-option-selected {
          ${tw`bg-white hover:bg-blue-5 active:bg-blue-15`}
          .ant-select-item-option-state {
            ${tw`!hidden`}
          }
        }
        .ant-select-item {
          padding: 6px 12px;
          &:hover {
            ${tw`!bg-blue-15`}
            .formio-user-label {
              ${tw`!text-darkBlue-15`}
            }
            .formio-user-orgUnit-label {
              ${tw`!text-gray-6`}
            }
          }
        }
      }

      .sidebar-search {
        .ant-select-clear {
          right: 15px;
          margin-top: -10px;
        }
      }
      .sidebar-search-select {
        ${tw`rounded-none !border !border-darkBlue-30`}
        box-shadow: 0 0 0 2px #C8D7F6 !important;
        min-height: 132px;
        max-height: 235px;
        overflow-y: scroll;

        .ant-select-group {
          ${tw`p-2 text-gray-8`}
          border-bottom: 1px solid #A3A2A3;
        }
        .ant-select-item {
          ${tw`p-2 text-sm text-gray-8`}
          border-bottom: 1px solid #ECECEC;
        }

        .ant-col {
          ${tw`flex items-center flex-col`}
        }

        img {
          max-width: 70%;
        }
      }

      body {
        .ant-dropdown {
          .language-selector-menu {
            display: flex;
            flex-direction: column;
            border: 1px solid #2e51a0;
            border-radius: 0;
            box-shadow: rgb(40 72 146 / 15%) 0px 0px 0px 2px;
            width: 100%;
            .ant-dropdown-menu-item {
              color: #727173;
            }
            .ant-dropdown-menu-item:hover {
              background: #f6f8fd;
              color: #284892;
            }
            .ant-dropdown-menu-item:active {
              background: #e4ebfa;
              color: #284892;
            }
          }
          .table-settings-dropdown {
            border: 1px solid #284892;
            position: absolute;
            border-radius: 0;
            right: -50%;
            left: -50%;
            box-shadow: rgba(40, 72, 146, 0.15) 0px 0px 0px 2px;
            .ant-divider {
              margin: 0 0 4px 0;
            }
            .ant-dropdown-menu-item {
              padding: 12px;
              .ant-dropdown-menu-title-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
              .ant-checkbox-wrapper {
                color: #727173;
                font-weight: 400;
              }
              &:hover {
                background: #f6f8fd;
                color: #284892;
                .ant-checkbox-wrapper {
                  color: #284892;
                }
                .ant-checkbox {
                  border: 1px solid #284892;
                  box-shadow: rgba(40, 72, 146, 0.15) 0px 0px 0px 2px;
                }
              }
            }
            .ant-dropdown-menu-item:first-of-type {
              .ant-dropdown-menu-title-content {
                color: #727173;
                width: 100%;
              }
              &:hover {
                background: transparent;
              }
            }
          }
        }
        .custom-headless-picker
          .ant-picker-header
          > button.ant-picker-header-super-prev-btn,
        .custom-headless-picker
          .ant-picker-header
          > button.ant-picker-header-super-next-btn,
        .custom-headless-picker .ant-picker-header button.ant-picker-year-btn {
          display: none !important;
        }

        .handsontable {
          .htDimmed {
            ${tw`bg-gray-0 text-gray-8`}
          }
          .htInvalid {
            ${tw`!bg-danger-0 border !border-danger-3`}
          }
          .htHidden {
            ${tw`!bg-gray-1 !text-transparent`}
          }
          .htRequired {
            ${tw`!bg-warning-0 border !border-warning-3`}
          }
          .htCommentTextArea {
            ${tw`!border-l-darkBlue-15 !px-3 !text-gray-8`}
          }
          .htCommentCell:after {
            ${tw`!border-t-darkBlue-15`}
          }
          td {
            ${tw`border !border-gray-3 !py-2.5`}
          }
          th,
          .ht__header {
            ${tw`!bg-blue-10 !text-darkBlue-15 !font-semiBold !border-blue-30`}
          }
          .ht_clone_left .ht__highlight {
            ${tw`!border-r-2 !border-l-0 !border-b-0 !border-darkBlue-30`}
          }
          .ht__highlight {
            ${tw`!bg-blue-25`}
          }
        }
        .htContextMenu {
          td {
            ${tw`!py-2 !text-xxs !h-3`}
            &.htSeparator {
              display: none !important;
            }
            span.selected {
              padding: 2px 4px !important;
            }
          }
        }
        .pika-single {
          border: 1px solid #d4d3d4;
          box-shadow: 0px 0px 16px rgba(40, 72, 146, 0.15);
          padding: 12px;

          thead {
            border-bottom: 1px solid #d4d3d4;
            th {
              color: #727173;
              font-weight: normal;
              abbr {
                text-decoration: none;
              }
            }
          }
        }
        .pika-label {
          color: #727173;
          font-weight: 500;
          font-size: 16px;
          padding-bottom: 12px;
        }
        .pika-button {
          padding: 8px;
          text-align: center;
          background-color: #fff;
          color: #727173;
          border-radius: 0;
          &:hover {
            background: #e4ebfa;
            color: #284892;
            border-radius: 0;
          }
        }
        .is-selected .pika-button {
          background-color: #284892;
          border-radius: 0;
          border: none;
          box-shadow: none;
          &:hover {
            color: #fff;
          }
        }
        .is-today .pika-button {
          background-color: #fff;
          border-radius: 0;
          border: 1px solid #284892;
          color: #284892;
          font-style: normal;
        }
        .is-disabled .pika-button {
          background-color: #fafafa;
          color: #727173;
          border-radius: 0;
          opacity: 1;
        }
        .calendar-popup {
          ${tw`mt-[7px] border border-gray-4 shadow-popover`}
          .ant-picker-panel-container {
            ${tw`shadow-none`}
          }
          .ant-picker-date-panel {
            ${tw`pt-4 px-5 pb-5 w-[344px]`}
          }
          .ant-picker-header {
            ${tw`px-0 border-none mb-2`}
          }
          .ant-picker-body {
            ${tw`p-0`}
            tr {
              ${tw`h-10 my-1`}
              td {
                ${tw`p-0`}
              }
              td:not(:last-child) {
                ${tw`mr-1`}
              }
            }
          }
          .ant-picker-content {
            ${tw`mx-0 w-full`}
            th {
              ${tw`text-gray-8 font-medium`}
            }
            thead > tr {
              ${tw`border-b border-gray-4`}
            }
          }
          .ant-picker-footer {
            ${tw`hidden`}
          }
          .ant-picker-header-view > button {
            ${tw`text-gray-8 font-medium text-base`}
          }
          .ant-picker-cell-in-view {
            ${tw`text-gray-8`}
          }
          .ant-picker-cell {
            .ant-picker-cell-inner {
              ${tw`!bg-transparent`}
            }
            :hover {
              ${tw`bg-blue-15`}
              .ant-picker-cell-inner {
                ${tw`bg-blue-15 text-darkBlue-15`}
              }
            }
            :active {
              ${tw`bg-blue-25`}
              .ant-picker-cell-inner {
                ${tw`bg-transparent text-darkBlue-15`}
              }
            }
          }
          .ant-picker-cell-selected {
            ${tw`bg-blue-25`}
            .ant-picker-cell-inner {
              ${tw`bg-transparent text-darkBlue-15`}
            }
          }
          .ant-picker-cell-today {
            ${tw`border border-darkBlue-15`}
            .ant-picker-cell-inner:before {
              ${tw`border-none`}
            }
          }
        }

        .notification {
          ${tw`p-[15px] h-12 !w-auto`}
          .ant-notification-notice-message {
            ${tw`text-sm`}
          }
          .ant-notification-notice-icon > span > svg {
            ${tw`w-7 h-7`}
          }
          .ant-notification-notice-icon {
            ${tw`ml-0`}
            span {
              ${tw`mb-0.5`}
            }
          }
          .ant-notification-notice-with-icon {
            ${tw`flex items-center`}
          }
          .ant-notification-notice-message {
            ${tw`ml-10 my-0 mr-0 p-0`}
          }
          .ant-notification-notice-description {
            ${tw`hidden`}
          }
        }

        .notification-success {
          ${tw`bg-success-0 border border-success-7`}
          .ant-notification-notice-message {
            ${tw`text-success-7`}
          }
          .ant-notification-notice-icon {
            ${tw`text-success-7`}
          }
        }
        .notification-error {
          ${tw`bg-danger-1 border border-danger`}
          .ant-notification-notice-message {
            ${tw`text-danger`}
          }
          .ant-notification-notice-icon {
            ${tw`text-danger`}
          }
        }
        .notification-info {
          ${tw`bg-blue-10 border border-primary`}
          .ant-notification-notice-message {
            ${tw`text-primary`}
          }
          .ant-notification-notice-icon {
            ${tw`text-primary`}
          }
        }

        .handsontable .htAutocompleteArrow {
          ${tw`text-gray-8`}
        }

        .handsontable.listbox td {
          ${tw`!pt-0 !pb-0`}
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }
        .fc a {
          color: #727173;
        }
        .fc-h-event .fc-event-title-container {
          ${tw`px-2`}
        }
        .fc .fc-toolbar-title {
          color: #59595a;
          text-transform: capitalize;
        }
        .fc .fc-button {
          border-radius: 0;
          border: none;
        }
        .fc .fc-button-primary:focus,
        .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
        .fc .fc-button-primary:not(:disabled):active:focus {
          box-shadow: none;
        }
        .ant-picker-ranges {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          .ant-picker-ok {
            margin-left: 0;
            button {
              height: 32px;
              font-size: 14px;
            }
          }
        }
        div#group-basic.collapse.show {
          visibility: visible !important;
        }
        .ant-logic-text-green {
          input,
          textarea,
          article,
          .choices__list,
          .ant-tree-select,
          .ant-select-selector,
          .formio-user-label {
            ${tw`text-success-7`}
          }
          &:focus {
            ${tw`text-success-7`};
          }
        }
      }
      .ant-logic-text-orange {
        input,
        textarea,
        article,
        .choices__list,
        .ant-tree-select,
        .ant-select-selector,
        .formio-user-label {
          ${tw`text-warning-7`};
          &:focus {
            ${tw`text-warning-7`};
          }
        }
      }
      .ant-logic-text-yellow {
        input,
        textarea,
        article,
        .choices__list,
        .ant-tree-select,
        .ant-select-selector,
        .formio-user-label {
          ${tw`text-pending-5`};
          &:focus {
            ${tw`text-pending-5`};
          }
        }
      }
      .ant-logic-text-red {
        input,
        textarea,
        article,
        .choices__list,
        .ant-tree-select,
        .ant-select-selector,
        .formio-user-label {
          ${tw`text-danger`};
          &:focus {
            ${tw`text-danger`};
          }
        }
      }
    `}
  />
);
