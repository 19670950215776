import { FC } from 'react';

import { ButtonProps, Modal as AntModal, ModalProps, Space } from 'antd';

import { Button } from '@components/atoms/Button';
import { EKAPLogoIcon } from '@components/atoms/Icon/EKAPLogoIcon';

const ModalTitle = () => {
  return (
    <div className="flex items-center gap-2">
      <EKAPLogoIcon />
      <p className="text-darkBlue-5 font-semiBold mb-0">EKAP</p>
    </div>
  );
};

export const ConfirmationModal: FC<ModalProps> = ({
  cancelButtonProps,
  cancelText,
  children,
  okButtonProps,
  okText,
  onCancel,
  onOk,
  ...props
}) => {
  const { size: cancelSize, ..._cancelBtnProps } = (cancelButtonProps ??
    {}) as ButtonProps;
  const { size: okSize, ..._okBtnProps } = (okButtonProps ?? {}) as ButtonProps;

  return (
    <AntModal
      bodyStyle={{ padding: '40px 24px' }}
      className=""
      closable={false}
      footer={
        <div className="pb-6 pt-6 px-3">
          <Space>
            <Button
              type={cancelButtonProps?.type ?? 'default'}
              {..._cancelBtnProps}
              onClick={onCancel}
            >
              {cancelText ?? 'Отменить'}
            </Button>
            <Button
              loading={props.confirmLoading}
              type={okButtonProps?.type ?? 'primary'}
              {..._okBtnProps}
              onClick={onOk}
            >
              {okText ?? 'ОК'}
            </Button>
          </Space>
        </div>
      }
      {...props}
      title={<ModalTitle />}
    >
      <div className="text-center">{children}</div>
    </AntModal>
  );
};
