import * as React from 'react';

import classNames from 'classnames';

// import { svgBaseProps, warning, useInsertStyles } from '../utils';

export interface IconBaseProps extends React.HTMLProps<HTMLSpanElement> {
  rotate?: number;
  spin?: boolean;
}

export interface CustomIconComponentProps {
  className?: string;
  fill: string;
  height: string | number;
  style?: React.CSSProperties;
  viewBox?: string;
  width: string | number;
}
export interface IconComponentProps extends IconBaseProps {
  ariaLabel?: React.AriaAttributes['aria-label'];
  component?:
    | React.ComponentType<
        CustomIconComponentProps | React.SVGProps<SVGSVGElement>
      >
    | React.ForwardRefExoticComponent<CustomIconComponentProps>;
  viewBox?: string;
}

const BaseIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>(
  (props, ref) => {
    const {
      // affect outter <i>...</i>
      children,

      // affect inner <svg>...</svg>
      className,
      component: Component,
      onClick,
      rotate,

      spin,
      tabIndex,

      // children
      viewBox,
      ...restProps
    } = props;

    // useInsertStyles();

    const prefixCls = 'anticon';

    const classString = classNames(prefixCls, className);

    const svgClassString = classNames({
      [`${prefixCls}-spin`]: !!spin,
    });

    const svgStyle = rotate
      ? {
          msTransform: `rotate(${rotate}deg)`,
          transform: `rotate(${rotate}deg)`,
        }
      : undefined;

    const innerSvgProps: CustomIconComponentProps = {
      // 'aria-hidden': 'true',
      // focusable: 'false',
      className: svgClassString,

      fill: 'none',

      height: '1em',

      style: svgStyle,
      viewBox,
      width: '1em',
    };

    if (!viewBox) {
      delete innerSvgProps.viewBox;
    }

    // component > children
    const renderInnerNode = () => {
      if (Component) {
        return <Component {...innerSvgProps}>{children}</Component>;
      }

      if (children) {
        return (
          <svg {...innerSvgProps} viewBox={viewBox}>
            {children}
          </svg>
        );
      }

      return null;
    };

    let iconTabIndex = tabIndex;
    if (iconTabIndex === undefined && onClick) {
      iconTabIndex = -1;
    }

    return (
      <span
        role="img"
        {...restProps}
        ref={ref}
        className={classString}
        tabIndex={iconTabIndex}
        onClick={onClick}
      >
        {renderInnerNode()}
      </span>
    );
  }
);

BaseIcon.displayName = 'EkapCustomAntdIcon';

export default BaseIcon;
