export const feedbackForm = {
  description: 'Сипаттама',
  dragOrSelectFile: 'Файлды осы жерге жүктеңіз немесе Файлды таңдаңыз',
  feedbackFormTitle: 'Кері байланыс нысаны',
  giveAnIdea: 'Идея беру',
  haveAnIdea: 'Сізде идея бар ма? Бізбен бөлісіңіз!',
  ideasCounterTitle: 'Идеялар есептегіші',
  improvementSuggestions: 'Жақсарту бойынша ұсыныстар',
  openForSuggestions:
    'Біз «eKAP» АЖ жақсартуға және ыңғайлырақ етуге көмектесетін ұсыныстарға әрқашан дайынбыз!',
  priority: 'Басымдық',
  rationalziationSuggestions: 'Рационализаторлық ұсыныстар',
  theme: 'Тақырып басымдық',
  totalSinceSystemStart: 'Жүйе басталған сәттен бастап барлығы:',
  uploadAttachmentsTitle:
    'Сіз өз идеяңызға қажетті барлық материалдарды жүктей аласыз',
};
