export const news = {
  addedBy: 'Добавил(-а): ',
  allEpisodes: 'Все выпуски',
  allNews: 'Все новости',
  clear: 'Очистить',
  copyLink: 'Копировать ссылку',
  createPost: 'Создать публикацию',
  daysAgo: 'Дней назад',
  kazatomPromNews: 'Новости казатомпрома',
  latestNews: 'Последние новости системы',
  liveFeed: 'Живая лента',
  newsTitle: 'Новости',
  position: 'Должность',
  published: 'Опубликовано',
};
