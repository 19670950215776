export const externalRedirectWithTokens = (options: {
  authToken?: string;
  redirectURL?: string | null;
  refreshToken?: string;
}) => {
  const { authToken, redirectURL, refreshToken } = options;

  if (!refreshToken || !authToken || !redirectURL) return;
  // if (!isValidURL(redirectURL)) return;

  const URL = `${redirectURL}/login?access_token=${authToken}&refresh_token=${refreshToken}`;

  window.location.href = URL;
};
