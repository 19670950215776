/**
 * ⚠️ DONT EDIT MANUALLY
 * This file is generated with `./src/cli/generateIcons.js`, check it for the detailed information
 */
import { FC } from 'react';

import { ReactComponent as EKAPLogoIconSvg } from '@assets/icons/EKAP-logo.svg';

import BaseIcon from './_BaseIcon';

type Props = React.ComponentPropsWithoutRef<'span'>;

export const EKAPLogoIcon: FC<Props> = (props) => {
  return (
    <BaseIcon
      component={EKAPLogoIconSvg}
      style={{ fontSize: '1.5em' }}
      {...props}
    />
  );
};
