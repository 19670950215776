export const liveFeed = {
  additionalProps: 'Дополнительные опции',
  allEvents: 'Все события',
  block: 'Блок',
  comment: 'Комментировать',
  department: 'Департамент',
  like: 'Нравится',
  position: 'Должность',
  section: 'Отдел',
  share: 'Поделиться',
};
