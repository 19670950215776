export const feedbackForm = {
  description: 'Описание',
  dragOrSelectFile: 'Перетащите файл сюда или Выберите файл',
  feedbackFormTitle: 'Форма обратной связи',
  giveAnIdea: 'Подать идею',
  haveAnIdea: 'У Вас есть идея? Поделитесь ею с нами!',
  ideasCounterTitle: 'Счетчик идей',
  improvementSuggestions: 'Предложений по улучшению',
  openForSuggestions:
    'Мы всегда открыты для предложений, которые помогут сделать лучше и удобнее ИС «eKAP»!',
  priority: 'Приоритет',
  rationalziationSuggestions: 'Рационализаторских предложений',
  theme: 'Тема',
  totalSinceSystemStart: 'Всего с момента старта системы',
  uploadAttachmentsTitle:
    'Также вы можете загрузить к вашей идее все необходимые материалы',
};
