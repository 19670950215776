import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import { authApi } from '@api/authApi';
import { appLocalStorage } from '@utils/appLocalStorage';

import { AppUserInfo, Permission, AppUsersList } from '@/types/AppUser';

type UserContextType = {
  authToken: string | null;
  isSettled: boolean | null;
  loadUserInfo: () => Promise<void>;
  loginByPhone: (
    username?: string,
    code?: string,
    requestId?: string
  ) => Promise<{
    access_token: string;
    refresh_token: string;
  }>;
  loginByUsername: (
    username: string,
    password: string
  ) => Promise<{
    access_token: string;
    refresh_token: string;
  }>;
  logout: () => void;
  setAuthToken: Dispatch<SetStateAction<string | null>>;
  setIsSettled: Dispatch<SetStateAction<boolean | null>>;
  userInfo: AppUserInfo | null;
  userPermissions: string[];
  userRoles: string[];
};

export const UserContext = createContext<UserContextType>({
  authToken: null,
  isSettled: false,
  loadUserInfo: async () => {
    //
  },
  loginByPhone: async () => ({
    access_token: '',
    refresh_token: '',
  }),
  loginByUsername: async () => ({
    access_token: '',
    refresh_token: '',
  }),
  logout: async () => {
    //
  },
  setAuthToken: () => {
    //
  },
  setIsSettled: () => {
    //
  },
  userInfo: null,
  userPermissions: [],
  userRoles: [],
});

export const UserContextProvider: React.FC = (props) => {
  const [authToken, setAuthToken] = useState(appLocalStorage.getAuthToken());
  const [userInfo, setUserInfo] = useState<AppUserInfo | null>(null);
  const [isSettled, setIsSettled] = useState<boolean | null>(false);

  const userPermissions = useMemo(() => {
    if (userInfo && userInfo.roles?.length) {
      const userRolePermissions = userInfo.roles.reduce(
        (prevValue, currentValue) => [
          ...prevValue,
          ...(currentValue.permissions ?? []),
        ],
        [] as Permission[]
      );

      return userRolePermissions.map((permission) => permission.name);
    }

    return [];
  }, [userInfo]);

  const userRoles = useMemo(() => {
    if (userInfo && userInfo.roles?.length) {
      return userInfo.roles.map((role) => role.name);
    }

    return [];
  }, [userInfo]);

  const loadUserInfo = async () => {
    try {
      setIsSettled(true);

      if (!authToken) return Promise.reject(401);

      // const _ui = await authApi.getUserInfo();
      const _ui = AppUsersList.find(item => authToken === item.token)
      setUserInfo(_ui ?? null);
    } catch (err) {
      // await kc.keycloak.updateToken(0);
    } finally {
      setIsSettled(false);
    }
  };

  const loginByUsername = async (username: string, password: string) => {
    try {
      const user = AppUsersList.find(item => username === item.username);
      // const tokens = await authApi.getUserByUsername(username, password);
      
      const tokens = {
        refresh_token: user?.token ?? '',
        access_token: user?.token ?? '',
      }
      setAuthToken(tokens?.access_token);

      appLocalStorage.persistTokens({
        refreshToken: tokens.refresh_token,
        token: tokens.access_token,
      });

      return { ...tokens };
      
    } finally {
      setIsSettled(false);
    }
  };

  const loginByPhone = async (username?: string, requestId?: string) => {
    try {
      setIsSettled(true);
      const tokens = await authApi.getUserByPhoneNumber(username, requestId);

      setAuthToken(tokens.access_token);

      appLocalStorage.persistTokens({
        refreshToken: tokens.refresh_token,
        token: tokens.access_token,
      });

      return { ...tokens };
    } finally {
      setIsSettled(false);
    }
  };

  const logout = () => {
    setAuthToken(null);
    setUserInfo(null);

    appLocalStorage.removeAuthToken();
    appLocalStorage.removeRefreshToken();
  };

  return (
    <UserContext.Provider
      value={{
        authToken,
        isSettled,
        loadUserInfo,
        loginByPhone,
        loginByUsername,
        logout,
        setAuthToken,
        setIsSettled,
        userInfo,
        userPermissions,
        userRoles,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
