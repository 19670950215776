import axios from 'axios';
import { stringify } from 'qs';

import { appLocalStorage } from '@utils/appLocalStorage';
export const cancelTokenSource = axios.CancelToken.source();

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_BASE_URL,
  cancelToken: cancelTokenSource.token,
  headers: {},
  // baseURL: 'https://test-api.kazatomprom.kz',
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
});

// insert auth token into headers
axiosInstance.interceptors.request.use(async (reqConfig) => {
  // const authToken = appLocalStorage.getAuthToken();
  const authToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1NWU2MGM4ZC0yZTk0LTRhOGMtYmQyZC04ODIyYzdiMjZhM2EiLCJuYW1lIjoiSm9obiBEb2UiLCJyb2xlcyI6W10sImlhdCI6MTUxNjIzOTAyMn0.6AkTuSI6K6x30xZJjQHXPd9ledeA82xpBmYnoPKvzqM';
  if (authToken && reqConfig.headers) {
    reqConfig.headers['Authorization'] = `Bearer ${authToken}`;
  }

  return reqConfig;
});
// catch 401 and refresh the token
axiosInstance.interceptors.response.use((res) => res);

export const api = axiosInstance;
