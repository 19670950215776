export const formBuilder = {
  'Add Another Text': 'Наименование кнопки',
  Blur: 'Наведение',
  Cancel: 'Отменить',
  Change: 'Изменение',
  'Clear Value When Hidden': 'Очистить значение, если оно скрыто',
  Component: '',
  Conditional: 'Условия',
  'Custom Error Message': 'Кастомный текст ошибки',
  'Custom Errors': 'Кастомные ошибки',
  'Custom Validation': 'Кастомная Валидация',
  Data: 'Данные',
  'Decimal Places': 'Ограничение по числу десятичных знаков',
  'Default Value': 'Значение по умолчанию',
  Description: 'Вспомогательный текст',
  'Description for this field.': 'Описание данного поля.',
  Disabled: 'Неактивный',
  Display: 'Основные настройки',
  'Drag and Drop a form Component': 'Перетащите сюда элемент формы ',
  'Enter the amount of rows': 'Введите количество строк',
  'Error Label': 'Наименование поля с ошибкой',
  Hidden: 'Скрыть поле',
  'Hide Input': 'Скрыть вводимое значение',
  'Hide Label': 'Скрыть наименование поля',
  'Initial Focus': 'Начальный фокус',
  'JSONLogic Validation': 'Валидация с помощью JSONLogic',
  Label: 'Наименование поля',
  Logic: 'Логика',
  'Maximum Length': 'Максимальная длина',
  'Maximum Value': 'Макс. значение',
  'Minimum Length': 'Минимальная длина',
  'Minimum Value': 'Мин. значение',
  'Modal Edit': 'Редактирование через модальное окно',
  'Multiple Values': 'Множественный выбор',
  Placeholder: 'Текст-плейсхолдер',
  Preview: 'Предварительный просмотр',
  Remove: 'Очистить',
  'Remove Row Text': 'Наименование кнопки удаления строки с элементами',
  'Require Decimal': 'Дробное число',
  Required: 'Обязательное поле',
  Rows: 'Количество строк',
  Save: 'Сохранить',
  'Save Row Text': 'Наименование кнопки сохранения строки с элементами',
  'Show Character Counter': 'Показывать количество символов',
  'Show Word Counter': 'Показывать количество слов',
  Submit: 'Сохранить',
  'Table View': 'Табличный вид',
  'To add a tooltip to this field, enter text here.':
    'Чтобы добавить подсказку к этому полю, введите текст здесь.',
  Tooltip: 'Подсказка (тултип)',
  Unique: 'Уникальное значение',
  'Use Thousands Separator': 'Использовать разделитель тысяч',
  'Validate On': 'Валидация на',
  Validation: 'Валидация',
};
