export const news = {
  addedBy: 'Added: ',
  allEpisodes: 'All issues',
  allNews: 'All news',
  clear: 'Clear',
  copyLink: 'Copy link',
  createPost: 'Create a post',
  daysAgo: 'Days ago',
  kazatomPromNews: 'Kazatomprom news',
  latestNews: 'Latest news of the system ',
  liveFeed: 'Live feed',
  newsTitle: 'News',
  position: 'Position',
  published: 'Published',
};
