import { covid19 } from './covid19';
import { feedbackForm } from './feedbackForm';
import { hse } from './hse';
import { liveFeed } from './liveFeed';
import { news } from './news';

export const newsModule = {
  covid19,
  feedbackForm,
  hse,
  liveFeed,
  news,
};
