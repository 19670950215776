export const covid19 = {
  covidSummary: {
    inKazAtomProm: 'Қазатомөнеркәсіп ҰАК АҚ бөлінісінде',
    inKazakhstan: 'Қазақстанда',
    title: 'Covid-19 бойынша жиынтық ақпарат',
  },
  healthInsuranceFund: 'Медициналық сақтандыру қоры',
  hotLine: 'Жаңа коронавирус мәселелері бойынша тәулік бойы жедел желі ашылды',
  nationalCenterForPublicHealth:
    'ҚР Денсаулық сақтау министрлігінің Қоғамдық денсаулық сақтау ұлттық орталығы',
};
