import { Group } from '@/types/Groups';
import { User } from '@/types/Users';

export enum USER_ROLES {
  ADMIN = 'admin',
  CRM_ADMIN = 'crm-admin',
  CRM_MANAGER = 'crm-manager',
  CRM_OBSERVER = 'crm-observer',
  EKAP_ADMIN = 'ekap-admin',
}

export enum PERMISSIONS {
  CREATE_CHECKLIST = 'ekap-permission-create-checklist',
  CREATE_COMMENT = 'ekap-permission-create-comment',
  CREATE_COUNTERPARTY = 'ekap-permission-create-counterparty',
  CREATE_DEAL = 'ekap-permission-create-deal',
  DELETE_CONTACT = 'ekap-permission-delete-contact',
  DELETE_DEAL = 'ekap-permission-delete-deal',
  EDIT_CHECKLIST = 'ekap-permission-edit-checklist',
  EDIT_COUNTERPARTY = 'ekap-permission-edit-counterparty',
  EDIT_DEAL = 'ekap-permission-edit-deal',
  MOVE_KANBAN_CARDS = 'ekap-permission-movr-kanban-cards',
}

const Position = {
  id: '',
  orgUnitId: '',
  orgUnitName: '',
  organizationId: '',
  organizationName: '',
  positionId: '',
  positionName: '',
  userId: ''
}

export const AppUsersList: AppUserInfo[] = [
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Alisher',
    id: '55e60c8d-2e94-4a8c-bd2d-8822c7b26a3a',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastErrorConnection: '',
    lastLoginAt: '',
    lastName: 'John',
    passwordLength: 0,
    patronymicName: 'Doe',
    photoUrl: 'https://example.com/photo1.jpg',
    roles: [],
    username: 'Alisher',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1NWU2MGM4ZC0yZTk0LTRhOGMtYmQyZC04ODIyYzdiMjZhM2EiLCJuYW1lIjoiSm9obiBEb2UiLCJyb2xlcyI6W10sImlhdCI6MTUxNjIzOTAyMn0.6AkTuSI6K6x30xZJjQHXPd9ledeA82xpBmYnoPKvzqM',
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Alice',
    id: '8dd797c3-2fd2-4ab3-93cf-2bc4b43be2a2',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastErrorConnection: '',
    lastLoginAt: '',
    lastName: 'Johnson',
    passwordLength: 0,
    patronymicName: 'Jane',
    photoUrl: 'https://example.com/photo1.jpg',
    roles: [],
    username: 'Alice',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI4ZGQ3OTdjMy0yZmQyLTRhYjMtOTNjZi0yYmM0YjQzYmUyYTIiLCJuYW1lIjoiQWxpY2UgSm9obnNvbiIsInJvbGVzIjpbXSwiaWF0IjoxNTE2MjM5MDIyfQ.Qm9PZ2puwHe19CI4gbbxArP2j9lpl2TYJmOKvccDVDk'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Dastan',
    id: 'd9fbb381-37c1-4374-9104-12c43557d0b7',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastErrorConnection: '',
    lastLoginAt: '',
    lastName: 'Bob',
    passwordLength: 0,
    patronymicName: 'Smith',
    photoUrl: 'https://example.com/photo3.jpg',
    roles: [],
    username: 'Dastan',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkOWZiYjM4MS0zN2MxLTQzNzQtOTEwNC0xMmM0MzU1N2QwYjciLCJuYW1lIjoiQm9iIFNtaXRoIiwicm9sZXMiOltdLCJpYXQiOjE1MTYyMzkwMjJ9.vtGuRxqi6frY6WnT-kyez7hpWkDIGFSd_Hay3b7-DK8'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Zhenis',
    id: '53e60c8d-2e94-4a8c-bd2d-8822c7b26a3a',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastErrorConnection: '',
    lastLoginAt: '',
    lastName: 'Brown',
    passwordLength: 0,
    patronymicName: 'Emily',
    photoUrl: 'https://example.com/photo4.jpg',
    roles: [],
    username: 'Zhenis',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1M2U2MGM4ZC0yZTk0LTRhOGMtYmQyZC04ODIyYzdiMjZhM2EiLCJuYW1lIjoiRW1pbHkgQnJvd24iLCJyb2xlcyI6W10sImlhdCI6MTUxNjIzOTAyMn0.EwEpAvMio2tZANfw1zAFQd3rFe735RdC-biqaz1-0es'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Azamat',
    id: '6dc33575-1c6b-4b5e-82ef-15a7e9e9b10a',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastErrorConnection: '',
    lastLoginAt: '',
    lastName: 'Michael',
    passwordLength: 0,
    patronymicName: 'Tyler',
    photoUrl: 'https://example.com/photo5.jpg',
    roles: [],
    username: 'Azamat',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2ZGMzMzU3NS0xYzZiLTRiNWUtODJlZi0xNWE3ZTllOWIxMGEiLCJuYW1lIjoiTWljaGFlbCBUYXlsb3IiLCJyb2xlcyI6W10sImlhdCI6MTUxNjIzOTAyMn0._idEQfDHmfL21ktRlBUjgthA3T3Rmvb__lWQoWT36kM'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Иван',
    id: '3a592c05-03d4-4ebd-b011-8e0d1c6751f1',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastErrorConnection: '',
    lastLoginAt: '',
    lastName: 'Петров',
    passwordLength: 0,
    patronymicName: 'Сидорович',
    photoUrl: 'https://example.com/photo6.jpg',
    roles: [],
    username: 'Иван',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzYTU5MmMwNS0wM2Q0LTRlYmQtYjAxMS04ZTBkMWM2NzUxZjEiLCJuYW1lIjoi0JjQstCw0L0g0J_QtdGC0YDQvtCyIiwicm9sZXMiOltdLCJpYXQiOjE1MTYyMzkwMjJ9.x-nd2fmiV0iYLnO4524gC2xiL7uMmv5KQX5dwllEjl8'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Анна',
    id: 'a5d20a8d-1a4f-49f2-91ec-ef98fc2eb68d',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastErrorConnection: '',
    lastLoginAt: '',
    lastName: 'Сидорова',
    passwordLength: 0,
    patronymicName: 'Алексеевна',
    photoUrl: 'https://example.com/photo7.jpg',
    roles: [],
    username: 'Анна',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhNWQyMGE4ZC0xYTRmLTQ5ZjItOTFlYy1lZjk4ZmMyZWI2OGQiLCJuYW1lIjoi0JDQvdC90LAg0KHQuNC00L7RgNC-0LLQsCIsInJvbGVzIjpbXSwiaWF0IjoxNTE2MjM5MDIyfQ.bYIfXGF0pXwlSfQQLCBMsN2n2j17hniWmVjdyJf_cTM'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Михаил',
    id: '702089cb-f318-46f6-b0a5-52cc1a1d6ebd',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastErrorConnection: '',
    lastLoginAt: '',
    lastName: 'Иванов',
    passwordLength: 0,
    patronymicName: '',
    photoUrl: 'https://example.com/photo8.jpg',
    roles: [],
    username: '+1122334455',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhNWQyMGE4ZC0xYTRmLTQ5ZjItOTFlYy1lZjk4ZmMyZWI2OGQiLCJuYW1lIjoi0JDQvdC90LAg0KHQuNC00L7RgNC-0LLQsCIsInJvbGVzIjpbXSwiaWF0IjoxNTE2MjM5MDIyfQ.bYIfXGF0pXwlSfQQLCBMsN2n2j17hniWmVjdyJf_cTM'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Елена',
    id: '91b0c9b1-16a4-4a7c-9a50-5876bf7cc474',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastErrorConnection: '',
    lastLoginAt: '',
    lastName: 'Алексеева',
    passwordLength: 0,
    patronymicName: '',
    photoUrl: 'https://example.com/photo9.jpg',
    roles: [],
    username: '+1555666777',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI5MWIwYzliMS0xNmE0LTRhN2MtOWE1MC01ODc2YmY3Y2M0NzQiLCJuYW1lIjoi0JXQu9C10L3QsCDQkNC70LXQutGB0LXQtdCy0LAiLCJyb2xlcyI6W10sImlhdCI6MTUxNjIzOTAyMn0.G--pc0uD_OqFJGoQ7yseB_Vd3MjppjZe1f9ASdVZNBQ'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Дмитрий',
    id: '26f99d82-c618-4184-b37a-14e6d235d7bf',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastErrorConnection: '',
    lastLoginAt: '',
    lastName: 'Павлов',
    passwordLength: 0,
    patronymicName: '',
    photoUrl: 'https://example.com/photo10.jpg',
    roles: [],
    username: '+1444333222',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNmY5OWQ4Mi1jNjE4LTQxODQtYjM3YS0xNGU2ZDIzNWQ3YmYiLCJuYW1lIjoi0JTQvNC40YLRgNC40Lkg0J_QsNCy0LvQvtCyIiwicm9sZXMiOltdLCJpYXQiOjE1MTYyMzkwMjJ9.yFQ7OpjlE307ZFJ2jSnnzri4Cz8NGHgag6hxY95IBkc'
  }
]

export const UsersList: User[] = [
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Alisher',
    id: '55e60c8d-2e94-4a8c-bd2d-8822c7b26a3a',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastName: 'John',
    password: '',
    position: Position,
    positions: [Position],
    passwordLength: 0,
    patronymicName: 'Doe',
    photoUrl: 'https://example.com/photo1.jpg',
    username: 'Alisher',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1NWU2MGM4ZC0yZTk0LTRhOGMtYmQyZC04ODIyYzdiMjZhM2EiLCJuYW1lIjoiSm9obiBEb2UiLCJyb2xlcyI6W10sImlhdCI6MTUxNjIzOTAyMn0.6AkTuSI6K6x30xZJjQHXPd9ledeA82xpBmYnoPKvzqM',
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Alice',
    id: '8dd797c3-2fd2-4ab3-93cf-2bc4b43be2a2',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastName: 'Johnson',
    password: '',
    position: Position,
    positions: [Position],
    passwordLength: 0,
    patronymicName: 'Jane',
    photoUrl: 'https://example.com/photo1.jpg',
    username: 'Alice',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI4ZGQ3OTdjMy0yZmQyLTRhYjMtOTNjZi0yYmM0YjQzYmUyYTIiLCJuYW1lIjoiQWxpY2UgSm9obnNvbiIsInJvbGVzIjpbXSwiaWF0IjoxNTE2MjM5MDIyfQ.Qm9PZ2puwHe19CI4gbbxArP2j9lpl2TYJmOKvccDVDk'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Dastan',
    id: 'd9fbb381-37c1-4374-9104-12c43557d0b7',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastName: 'Bob',
    password: '',
    position: Position,
    positions: [Position],
    passwordLength: 0,
    patronymicName: 'Smith',
    photoUrl: 'https://example.com/photo3.jpg',
    username: 'Dastan',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkOWZiYjM4MS0zN2MxLTQzNzQtOTEwNC0xMmM0MzU1N2QwYjciLCJuYW1lIjoiQm9iIFNtaXRoIiwicm9sZXMiOltdLCJpYXQiOjE1MTYyMzkwMjJ9.vtGuRxqi6frY6WnT-kyez7hpWkDIGFSd_Hay3b7-DK8'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Zhenis',
    id: '53e60c8d-2e94-4a8c-bd2d-8822c7b26a3a',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastName: 'Brown',
    password: '',
    position: Position,
    positions: [Position],
    passwordLength: 0,
    patronymicName: 'Emily',
    photoUrl: 'https://example.com/photo4.jpg',
    username: 'Zhenis',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1M2U2MGM4ZC0yZTk0LTRhOGMtYmQyZC04ODIyYzdiMjZhM2EiLCJuYW1lIjoiRW1pbHkgQnJvd24iLCJyb2xlcyI6W10sImlhdCI6MTUxNjIzOTAyMn0.EwEpAvMio2tZANfw1zAFQd3rFe735RdC-biqaz1-0es'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Azamat',
    id: '6dc33575-1c6b-4b5e-82ef-15a7e9e9b10a',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastName: 'Michael',
    password: '',
    position: Position,
    positions: [Position],
    passwordLength: 0,
    patronymicName: 'Tyler',
    photoUrl: 'https://example.com/photo5.jpg',
    username: 'Azamat',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2ZGMzMzU3NS0xYzZiLTRiNWUtODJlZi0xNWE3ZTllOWIxMGEiLCJuYW1lIjoiTWljaGFlbCBUYXlsb3IiLCJyb2xlcyI6W10sImlhdCI6MTUxNjIzOTAyMn0._idEQfDHmfL21ktRlBUjgthA3T3Rmvb__lWQoWT36kM'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Иван',
    id: '3a592c05-03d4-4ebd-b011-8e0d1c6751f1',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastName: 'Петров',
    password: '',
    position: Position,
    positions: [Position],
    passwordLength: 0,
    patronymicName: 'Сидорович',
    photoUrl: 'https://example.com/photo6.jpg',
    username: 'Иван',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzYTU5MmMwNS0wM2Q0LTRlYmQtYjAxMS04ZTBkMWM2NzUxZjEiLCJuYW1lIjoi0JjQstCw0L0g0J_QtdGC0YDQvtCyIiwicm9sZXMiOltdLCJpYXQiOjE1MTYyMzkwMjJ9.x-nd2fmiV0iYLnO4524gC2xiL7uMmv5KQX5dwllEjl8'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Анна',
    id: 'a5d20a8d-1a4f-49f2-91ec-ef98fc2eb68d',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastName: 'Сидорова',
    password: '',
    position: Position,
    positions: [Position],
    passwordLength: 0,
    patronymicName: 'Алексеевна',
    photoUrl: 'https://example.com/photo7.jpg',
    username: 'Анна',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhNWQyMGE4ZC0xYTRmLTQ5ZjItOTFlYy1lZjk4ZmMyZWI2OGQiLCJuYW1lIjoi0JDQvdC90LAg0KHQuNC00L7RgNC-0LLQsCIsInJvbGVzIjpbXSwiaWF0IjoxNTE2MjM5MDIyfQ.bYIfXGF0pXwlSfQQLCBMsN2n2j17hniWmVjdyJf_cTM'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Михаил',
    id: '702089cb-f318-46f6-b0a5-52cc1a1d6ebd',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastName: 'Иванов',
    password: '',
    position: Position,
    positions: [Position],
    passwordLength: 0,
    patronymicName: '',
    photoUrl: 'https://example.com/photo8.jpg',
    username: '+1122334455',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhNWQyMGE4ZC0xYTRmLTQ5ZjItOTFlYy1lZjk4ZmMyZWI2OGQiLCJuYW1lIjoi0JDQvdC90LAg0KHQuNC00L7RgNC-0LLQsCIsInJvbGVzIjpbXSwiaWF0IjoxNTE2MjM5MDIyfQ.bYIfXGF0pXwlSfQQLCBMsN2n2j17hniWmVjdyJf_cTM'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Елена',
    id: '91b0c9b1-16a4-4a7c-9a50-5876bf7cc474',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastName: 'Алексеева',
    password: '',
    position: Position,
    positions: [Position],
    passwordLength: 0,
    patronymicName: '',
    photoUrl: 'https://example.com/photo9.jpg',
    username: '+1555666777',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI5MWIwYzliMS0xNmE0LTRhN2MtOWE1MC01ODc2YmY3Y2M0NzQiLCJuYW1lIjoi0JXQu9C10L3QsCDQkNC70LXQutGB0LXQtdCy0LAiLCJyb2xlcyI6W10sImlhdCI6MTUxNjIzOTAyMn0.G--pc0uD_OqFJGoQ7yseB_Vd3MjppjZe1f9ASdVZNBQ'
  },
  {
    authenticationPhone: '',
    contactPhones: [''],
    createdDate: '',
    email: '',
    extraInfo: '',
    firstName: 'Дмитрий',
    id: '26f99d82-c618-4184-b37a-14e6d235d7bf',
    iin: '',
    isEnabled: true,
    lastConnection: '',
    lastName: 'Павлов',
    password: '',
    position: Position,
    positions: [Position],
    passwordLength: 0,
    patronymicName: '',
    photoUrl: 'https://example.com/photo10.jpg',
    username: '+1444333222',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNmY5OWQ4Mi1jNjE4LTQxODQtYjM3YS0xNGU2ZDIzNWQ3YmYiLCJuYW1lIjoi0JTQvNC40YLRgNC40Lkg0J_QsNCy0LvQvtCyIiwicm9sZXMiOltdLCJpYXQiOjE1MTYyMzkwMjJ9.yFQ7OpjlE307ZFJ2jSnnzri4Cz8NGHgag6hxY95IBkc'
  }
]

export type AppUserInfo = {
  authenticationPhone: string;
  contactPhones: string[];
  content?: User[];
  createdDate: string;
  email: string;
  employeeId?: string;
  extraInfo: string;
  firstName: string;
  groups?: Group[];
  id: string;
  iin: string;
  isEnabled: boolean;
  lastConnection: Date | string;
  lastErrorConnection: Date | string;
  lastLoginAt: string;
  lastName: string;
  passwordLength: number;
  patronymicName: string;
  photoUrl: string;
  position?: SignlePosition;
  roles: Role[];
  username: string;
  token?: string;
};

export type SignlePosition = {
  orgUnitId: string;
  orgUnitName: string;
  organizationId: string;
  organizationName: string;
  positionId: string;
  positionName: string;
};

export type Position = {
  createdAt: string;
  createdBy: string;
  description: string;
  id: string;
  name: string;
  numberOfUsers: number;
  updatedAt: string;
  updatedBy: string;
};

export type Role = {
  description: string;
  id: string;
  name: string;
  permissions?: Permission[];
};

export type Permission = {
  description: string;
  id: string;
  name: string;
};
