export const covid19 = {
  covidSummary: {
    inKazAtomProm: 'In the context of JSC NAC Kazatomprom',
    inKazakhstan: 'In Kazakhstan',
    title: 'Summary on Covid-19',
  },
  healthInsuranceFund: 'Health Insurance Fund',
  hotLine: 'A new coronavirus hotline is open 24 hours a day',
  nationalCenterForPublicHealth:
    'National Public Health Center of the Ministry of Health of the Republic of Kazakhstan',
};
