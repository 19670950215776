export const liveFeed = {
  additionalProps: 'Additional options',
  allEvents: 'All events',
  block: 'Block',
  comment: 'Comment',
  department: 'Department',
  like: 'Like',
  position: 'Position',
  section: 'Division',
  share: 'Share',
};
