export const feedbackForm = {
  description: 'Description',
  dragOrSelectFile: 'Drag file here or select a file',
  feedbackFormTitle: 'Feedback form',
  giveAnIdea: 'Pitch an idea',
  haveAnIdea: 'Do you have an idea? Share it with us!',
  ideasCounterTitle: 'Idea Counter',
  improvementSuggestions: 'Improvement proposals',
  openForSuggestions:
    'We are always open to suggestions that will help to make better and more convenient IS «eKAP»!',
  priority: 'Priority',
  rationalziationSuggestions: 'Rationalization proposals',
  theme: 'Subject ',
  totalSinceSystemStart: 'Total from the start of the system:',
  uploadAttachmentsTitle:
    'You can also download all the necessary materials for your idea.',
};
