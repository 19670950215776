export const header = {
  employees: 'Employees',
  events: 'Events',
  help: 'Help',
  liveFeed: 'Live feed',
  main: 'Home page',
  news: 'News',
  notifications: 'Notifications',
  signIn: 'Enter',
  signOut: 'Exit',
  test: 'Testing',
};
