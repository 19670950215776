import { lazy, Suspense, useContext, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import { FullPageSpinner } from '@components/atoms/FullPageSpinner';
import { ProtectedRoute } from '@components/atoms/ProtectedRoute';
import { UserContext } from '@context/UserContext';
import { externalRedirectWithTokens } from '@modules/auth/utils/externalRedirectWithTokens';
import { appLocalStorage } from '@utils/appLocalStorage';
// import { ProfileRoutes } from '@modules/profile/ProfileRoutes';

// const PageLayout = lazy(() =>
//   import('./modules/landing/components/templates/PageLayout').then(
//     (module) => ({
//       default: module.PageLayout,
//     })
//   )
// );
const UserLayout = lazy(() =>
  import('@components/templates/UserLayout').then((module) => ({
    default: module.UserLayout,
  }))
);
const MedicalLayout = lazy(() =>
  import('@modules/medical/components/templates/MedicalLayout').then(
    (module) => ({
      default: module.MedicalLayout,
    })
  )
);
const WorkPermitLayout = lazy(() =>
  import('@modules/work-permit/components/templates/WorkPermitLayout').then(
    (module) => ({
      default: module.WorkPermitLayout,
    })
  )
);
const LoginPage = lazy(() =>
  import('@modules/auth/pages/LoginPage').then((module) => ({
    default: module.LoginPage,
  }))
);
const TaskPermitLayout = lazy(() =>
  import('@modules/task-permit/components/templates/TaskPermitLayout').then(
    (module) => ({
      default: module.TaskPermitLayout,
    })
  )
);
// const AdminRoutes = lazy(() =>
//   import('@modules/admin/AdminRoutes').then((module) => ({
//     default: module.AdminRoutes,
//   }))
// );
// const AuthenticationPage = lazy(
//   () => import('@modules/landing/pages/AuthenticationPage')
// );

const MedicalRoutes = lazy(() =>
  import('@modules/medical/MedicalRoutes').then((module) => ({
    default: module.MedicalRoutes,
  }))
);

const WorkPermitRoutes = lazy(() =>
  import('@modules/work-permit/WorkPermitRoutes').then((module) => ({
    default: module.WorkPermitRoutes,
  }))
);

const TaskPermitRoutes = lazy(() =>
  import('@modules/task-permit/TaskPermitRoutes').then((module) => ({
    default: module.TaskPermitRoutes,
  }))
);

function AppRoutes() {
  const { authToken, isSettled, loadUserInfo } = useContext(UserContext);
  const [params] = useSearchParams();
  const { pathname } = useLocation();

  const paramPath = params.get('path') || '/';

  useEffect(() => {
    loadUserInfo();
  }, [authToken]);

  if (isSettled) {
    return (
      <FullPageSpinner
        subtitle="При проблемах проверьте своё подключение к интернету или к VPN"
        title="Проверка авторизационного ключа"
      />
    );
  }

  if (
    appLocalStorage.getAuthToken() &&
    appLocalStorage.getRefreshToken() &&
    !isSettled &&
    params.get('externalRedirectURL')
  ) {
    externalRedirectWithTokens({
      authToken: appLocalStorage.getAuthToken() || '',
      redirectURL: params.get('externalRedirectURL'),
      refreshToken: appLocalStorage.getRefreshToken() || '',
    });
  }

  return (
    <Suspense fallback={<FullPageSpinner title="Загрузка страницы..." />}>
      <Routes>
        <Route
          element={
            authToken ? (
              <UserLayout />
            ) : (
              <Navigate
                to={`/login${
                  pathname && pathname !== '/' ? `?path=${pathname}` : ''
                }`}
              />
            )
          }
          path="/"
        >
          {/* <Route element={<Navigate to="/tasks/my" />} path="/" /> */}
          <Route element={<Navigate to="/work-permit/list" />} path="/" />
          {/* <Route
            element={
              <ProtectedRoute>
                <AdminRoutes />
              </ProtectedRoute>
            }
            path="/admin/*"
          /> */}
          {/* <Route
            element={
              <ProtectedRoute>
                <CrmRoutes />
              </ProtectedRoute>
            }
            path="/crm/*"
          /> */}
          {/* <Route element={<ReportsRoutes />} path="/reports/*" />
          <Route element={<UserModulesRoutes />} path="/user/*" />
          <Route element={<ServicesRoutes />} path="/services/*" /> */}
          {/* <Route element={<ProfileRoutes />} path='/profile/*'/> */}
        </Route>

        {/* <Route element={<PageLayout />} path="/">
          <Route element={<LandingRoutes />} path="/news/*" />
        </Route> */}

        {/* <Route element={<AuthenticationPage />} path="/authenticate" /> */}
        <Route
          element={!authToken ? <LoginPage /> : <Navigate to={paramPath} />}
          path="/login"
        />
        <Route
          element={authToken ? <MedicalLayout /> : <Navigate to="/login" />}
          path="/"
        >
          <Route element={<MedicalRoutes />} path="/medicalCheckup/*" />
        </Route>

        <Route
          element={authToken ? <MedicalLayout /> : <Navigate to="/login" />}
          path="/"
        >
          <Route element={<MedicalRoutes />} path="/medicalCheckup/*" />
        </Route>

        <Route
          element={authToken ? <WorkPermitLayout /> : <Navigate to="/login" />}
          path="/"
        >
          <Route element={<WorkPermitRoutes />} path="/work-permit/*" />
        </Route>

        <Route
          element={authToken ? <TaskPermitLayout /> : <Navigate to="/login" />}
          path="/"
        >
          <Route element={<TaskPermitRoutes />} path="/task-permit/*" />
        </Route>

        {/* <Route
          element={authToken ? <PnaLayout /> : <Navigate to="/login" />}
          path="/"
        >
          <Route element={<PnaRoutes />} path="/pna/*" />
        </Route> */}
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
