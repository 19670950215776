export function ignoreConsoleErrors() {
  const consoleError = console.error;
  const ignoredErrors = ['is deprecated in StrictMode'];

  console.error = function filterErrors(msg, ...args) {
    if (
      typeof msg !== 'string' ||
      (!ignoredErrors.some((error) => msg?.includes(error)) &&
        !args.includes('findDOMNode'))
    ) {
      consoleError(msg, ...args);
    }
  };
}
